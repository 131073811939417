<template>
  <table id="partners">
    <tr>
      <th style="width: 2%;">ID</th>
      <th style="width: 10%;">Name</th>
      <th style="width: 5%;">Owner</th>
      <th style="width: 2%;">Type</th>
      <th style="width: 5%;">Country</th>
      <th style="width: 2%;">Currency</th>
      <th style="width: 5%;">Website</th>
      <th style="width: 5%;">Commission</th>
      <th style="width: 2%;">Akcja</th>
    </tr>

    <template v-for="partner in partnerList" v-bind:key="partner.id">
      <tr>
        <td>{{ partner.id }}</td>
        <td>{{ partner.name }}</td>
        <td>{{ partner.owner ? partner.owner.email : null}}</td>
        <td>{{ partner.type ? partner.type.type : null}}</td>
        <td>{{ partner.country ? partner.country.name : null}}</td>
        <td>{{ partner.currency ? partner.currency.code : null }}</td>
        <td>{{ partner.website }}</td>
        <td>{{ partner.commission }}</td>
        <td><router-link v-bind:to="'/admin/partner/' + partner.id" tag="button">Wyświetl</router-link></td>
      </tr>
    </template>
  </table>
</template>

<script>
import SalesPackagePartnerService from '../../../services/admin/SalesPackage/salesPackagePartnerService';

export default {
  name: 'PartnerList',
  data() {
    return {
      partnerList: []
    };
  },
  mounted() {
    this.displayPartnerLists();
  },
  methods: {
    async displayPartnerLists() {
      const response = await SalesPackagePartnerService.getPartnerList();
      this.partnerList = response.data.partners.items;
    }
  }
};
</script>

<style scoped>
#partners {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#partners td, #partners th {
  border: 1px solid #ddd;
  padding: 8px;
}

#partners tr:nth-child(even){background-color: #f2f2f2;}

#partners tr:hover {background-color: #ddd;}

#partners th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
